/**
 * Represents the main Swiper instance for the hero section.
 * @type {Swiper}
 */
var swiper_main = new Swiper(".hero-swiper", {
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

var swiper_secondary = new Swiper(".second-swiper", {
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true,
  },
});

var swiper_secondary = new Swiper(".third-swiper", {
  autoplay: {
    delay: 7000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true,
  },
});

// Function to update swiper widths
function updateSwiperWidths() {
  // Get the elements
  const heroMain = document.querySelector(".hero-main");
  const secondaryFirst = document.querySelector(".secondary-first");
  const secondarySecond = document.querySelector(".secondary-second");

  if (!heroMain || !secondaryFirst || !secondarySecond) {
    console.error("One or more elements not found.");
    return; // Exit the function if any element is missing
  }

  const swiperMain = heroMain.querySelector(".swiper");
  const swiperFirst = secondaryFirst.querySelector(".swiper");
  const swiperSecond = secondarySecond.querySelector(".swiper");

  if (!swiperMain || !swiperFirst || !swiperSecond) {
    console.error("One or more swiper elements not found.");
    return; // Exit the function if any swiper element is missing
  }

  // Get the computed widths
  const heroMainWidth = window.getComputedStyle(heroMain).width;
  const secondaryFirstWidth = window.getComputedStyle(secondaryFirst).width;
  const secondarySecondWidth = window.getComputedStyle(secondarySecond).width;

  // Set the widths to the swipers
  swiperMain.style.width = heroMainWidth;
  swiperFirst.style.width = secondaryFirstWidth;
  swiperSecond.style.width = secondarySecondWidth;
}

// Function to call updateSwiperWidths when needed
function initializeSwiperUpdate() {
  // Call function when the page is fully loaded
  updateSwiperWidths();

  // Event listener for window resize
  window.addEventListener("resize", updateSwiperWidths);
}

// Event listener for DOMContentLoaded
document.addEventListener("DOMContentLoaded", initializeSwiperUpdate);
